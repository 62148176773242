.member-details-overlay {
	.details-tab {
		.edit-mode-button {
			margin: 0px;
		}

		.archive-button {
			margin: 0px;
		}

		.force-unarchive-button {
			margin: 0px;
			margin-top: -2px;
		}

		.edit-form {
			.form-group div {
				display: flex;
				align-items: center;
			}
		}

		.tab-data-edit-mode-input-field,
		.tab-data-edit-mode-select-field,
		.tab-data-edit-mode-group-field {
			width: 100%;
			flex-wrap: wrap;

			.pill-section {
				flex-basis: 100%;

				.pill {
					font-size: 12px;

					.close {
						padding: 0px;
						margin: 0px;
						margin-left: 0.25rem;
						color: inherit;
						font-size: 12px;
						text-shadow: 0 1px 0 rgba(#000, 0.5);
					}
				}
				.add-button {
					float: left;
					margin-left: auto;
				}

				.no-values {
					padding: 15px;
					background-color: rgba(225, 226, 227, 0.5);
					color: #6c6762;
					font-size: 10px;
					border-radius: 5px;
				}
			}

			.add-section {
				width: 100%;

				.add-button {
					float: left;
					margin-left: 10px;
				}
			}
		}

		.tab-data-edit-mode-group-field {
			.add-section {
				span {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}

	.documents-tab {
		.documents-table {
		}

		.upload-document {
			.back-button {
				border-top: 1px solid #ddd;
				margin-top: 10px;
				button {
					// font-weight: 400;
				}
			}
		}

		.select-atttributes {
			h6 {
				text-align: center;
			}
			.next-button {
				border-top: 1px solid #ddd;
				margin-top: 10px;
				button {
					// font-weight: 400;
				}
			}
		}

		.confirm-upload {
			.form-group div {
				display: flex;
				align-items: center;
			}
		}
	}
}
